<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card style="min-height: 40vh;">
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{cvCardTitle}}</h4>
          </template>
          <template v-slot:body>
            <p v-show="!propOpenedInModal">{{cvCardSubHeader}}</p>
            <form action="#" v-if="vmSpcmFormData && Object.keys(vmSpcmFormData).length > 0">
              <div class="form-row">

                <div class="col-12 mb-3">
                  <label>Select User</label>
                  <multiselect
                    v-model="selectedCounsellor"
                    :options="cvUserList"
                    placeholder="Select User"
                    label="user_email"
                    @search-change="seachUserList($event)"
                    track-by="user_name">
                  </multiselect>
                </div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                   <b-spinner label="Spinning"></b-spinner>
                </button>
                <button type="button" class="btn btn-primary" @click="spcmEdit()">{{cvSubmitBtn}}</button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import { Spcms } from "../../../FackApi/api/Spcm"
import ApiResponse from "../../../Utils/apiResponse.js"
import Multiselect from "vue-multiselect"
import { User } from "../../../FackApi/api/user"
import userPermission from "../../../Utils/user_permission.js"

export default {
  name: "SpcmEdit",
  components: {
    Multiselect
  },
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propSpcmObj: {
      type: Object,
      default: function () {
        return {
          "counsellor_id": "",
          "spcm_state": null
        }
      }
    }
  },
  data () {
    return {
      apiName: "spcm_edit",
      cvCardTitle: "Edit Spcm",
      cvCardSubHeader: "Edit Spcm ",
      cvSubmitBtn: "Edit",
      cvCounsellorIdLabel: "counsellor id",
      cvLoadingStatus: false,
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Spcm Edit Response",
      vmSpcmFormData: {},
      cvUserList: [],
      selectedCounsellor: {}
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
    this.spcmView()
    this.seachUserList()
  },
  methods: {
    /**
     * seachUserList
     */
    async seachUserList (event) {
      let payload = {
        match_with: null,
        user_email: event,
        user_role: "USERROLE11118",
        limit: 10
      }

      if (event) {
        payload.match_with = "user_email"
      }

      const userListResp = await User.userListSearch(this, payload)
      if (userListResp.resp_status) {
        this.cvUserList = userListResp.resp_data.data
      }
      else {
        this.cvUserList = []
      }
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmSpcmFormData) {
          if (!this.vmSpcmFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * spcmView
     */
    async spcmView () {
      try {
        if (this.propOpenedInModal) {
          this.vmSpcmFormData = this.propSpcmObj
        }
        else {
          let spcmId = this.$route.params.spcm_id
          let spcmViewResp = await Spcms.spcmView(this, spcmId)

          if (spcmViewResp && spcmViewResp.resp_status) {
            this.vmSpcmFormData = spcmViewResp.resp_data.data
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at spcmView() and Exception:", err.message)
      }
    },
    /**
     * spcmEdit
     */
    async spcmEdit () {
      try {
        // if (!this.validate()) {
        //   return false
        // }
        this.cvLoadingStatus = true
        let payload = {
          spcm_id: this.vmSpcmFormData.spcm_id,
          counsellor_id: this.selectedCounsellor.user_id,
          gide_user_id: this.vmSpcmFormData.user_id
        }

        let spcmAddResp = await Spcms.spcmEdit(this, payload)
        await ApiResponse.responseMessageDisplay(this, spcmAddResp)

        if (spcmAddResp && !spcmAddResp.resp_status) {
          return false
        }

        this.vmSpcmFormData.counsellor_id = this.selectedCounsellor.user_id
        this.vmSpcmFormData.counsellor_email = this.selectedCounsellor.user_email

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseSpcmEditModal", this.vmSpcmFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at spcmEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
